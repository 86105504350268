///src/services/Shopify/hooks.ts
import { useCallback, useEffect, useMemo, useState } from 'react'
import { ApolloQueryResult, gql as shopifyGQL, useMutation, useQuery } from '@apollo/client'
import { getCookie } from 'cookies-next'
import { AUTH_JWT_COOKIE } from '@/constants/cookies'
import { getShopifyClient } from '@/services/ApolloClient/ApolloClient'
import { handleCartUnknownError } from '@/services/Shopify/utils'
import {
  AddLinesShopifyCartMutation,
  AddLinesShopifyCartMutationVariables,
  AttributeInput,
  CartLineInput,
  CartLineUpdateInput,
  CreateShopifyCartMutation,
  CreateShopifyCartMutationVariables,
  GetShopifyCustomerAndOrdersQuery,
  GetShopifyCustomerAndOrdersQueryVariables,
  RemoveLinesShopifyCartMutation,
  RemoveLinesShopifyCartMutationVariables,
  UpdateLinesShopifyCartMutation,
  UpdateLinesShopifyCartMutationVariables,
  GetShopifyAccessTokenFromMultipassMutation,
  GetShopifyAccessTokenFromMultipassMutationVariables,
  Cart,
} from '@/types/codegen-shopify'
import { logger } from '@/utils/logging'
import { getStringOrEmptyString, getValidString } from '@/utils/primitives/strings'
import { createShopifyClient } from './ShopifyClient'
import { getShopifyAccessTokenFromMultipass, getShopifyCustomerAndOrders, GetShopifyProduct } from './queries'

export const cartFragment = shopifyGQL/* GraphQL */ `
  fragment cartFragment on Cart {
    id
    checkoutUrl
    cost {
      totalAmount {
        amount
        currencyCode
      }
    }
    totalQuantity
    discountCodes {
      code
      applicable
    }
  }
`

export const userErrorsFragment = shopifyGQL/* GraphQL */ `
  fragment userErrorsFragment on CartUserError {
    code
    field
    message
  }
`

export const CreateShopifyCart = shopifyGQL/* GraphQL */ `
  ${cartFragment}
  ${userErrorsFragment}
  mutation createShopifyCart($lines: [CartLineInput!], $attributes: [AttributeInput!]) {
    cartCreate(input: { lines: $lines, attributes: $attributes }) {
      cart {
        ...cartFragment
        attributes {
          key
          value
        }
      }
      userErrors {
        ...userErrorsFragment
      }
    }
  }
`

export const AddLinesShopifyCart = shopifyGQL/* GraphQL */ `
  ${cartFragment}
  ${userErrorsFragment}
  mutation addLinesShopifyCart($cartId: ID!, $lines: [CartLineInput!]!) {
    cartLinesAdd(cartId: $cartId, lines: $lines) {
      cart {
        ...cartFragment
      }
      userErrors {
        ...userErrorsFragment
      }
    }
  }
`

export const RemoveLinesShopifyCart = shopifyGQL/* GraphQL */ `
  ${cartFragment}
  ${userErrorsFragment}
  mutation removeLinesShopifyCart($cartId: ID!, $lineIds: [ID!]!) {
    cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
      cart {
        ...cartFragment
      }
      userErrors {
        ...userErrorsFragment
      }
    }
  }
`

export const UpdateLinesShopifyCart = shopifyGQL/* GraphQL */ `
  ${cartFragment}
  ${userErrorsFragment}
  mutation updateLinesShopifyCart($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
    cartLinesUpdate(cartId: $cartId, lines: $lines) {
      cart {
        ...cartFragment
      }
      userErrors {
        ...userErrorsFragment
      }
    }
  }
`

export const ApplyDiscountCode = shopifyGQL/* GraphQL */ `
  ${cartFragment}
  ${userErrorsFragment}
  mutation applyDiscountCode($cartId: ID!, $discountCodes: [String!]!) {
    cartDiscountCodesUpdate(cartId: $cartId, discountCodes: $discountCodes) {
      cart {
        ...cartFragment
      }
      userErrors {
        ...userErrorsFragment
      }
    }
  }
`

export function useShopifyCustomerAndOrders({
  customerAccessToken,
  storeUrl,
}: {
  customerAccessToken: string
  storeUrl: string
}) {
  const shopifyClient = createShopifyClient({ baseUrl: storeUrl, accessToken: customerAccessToken })
  const { data, loading, error } = useQuery(getShopifyCustomerAndOrders, {
    variables: {
      customerAccessToken,
    },
    client: shopifyClient,
    errorPolicy: 'all',
  })
  return { data, loading, error }
}

const getMultipassToken = async (authToken: string) => {
  try {
    // for some reason process.env.OAUTH2_ISSUER_BASE_URL is returning angel.com/undefined ¯\_(ツ)_/¯
    const response = await fetch('https://auth.angel.com/login-shopify-multipass?redirect_uri=none', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${authToken}`,
      },
    })
    const res: { multipass_token: string } = await response.json()
    return res.multipass_token
  } catch (e) {
    return null
  }
}

interface ShopifyOrderData extends ApolloQueryResult<GetShopifyCustomerAndOrdersQuery> {
  totalEntries: number
  totalTickets: string
}

const sumEntriesAndTickets = (res: ApolloQueryResult<GetShopifyCustomerAndOrdersQuery> | undefined) => {
  if (res) {
    const totalEntries = res?.data.customer?.orders.edges
      .filter((item) => item.node.cancelReason !== null || item.node.cancelReason !== undefined)
      .filter((item) =>
        item.node.lineItems.edges.some((lineItem) =>
          lineItem.node.variant?.product.productType.includes('homestead-sweepstakes'),
        ),
      )
      .map((item) => {
        return item.node.lineItems.edges.reduce((acc, lineItem) => {
          if (lineItem.node.variant?.product.productType === 'homestead-sweepstakes') {
            return acc + lineItem.node.quantity
          }
          return acc
        }, 0)
      })
      .reduce((acc, item) => acc + item, 0)

    const totalTickets = res?.data.customer?.orders.edges
      .filter((item) => item.node.cancelReason !== null || item.node.cancelReason !== undefined)
      .filter((item) =>
        item.node.lineItems.edges.some((lineItem) => lineItem.node.variant?.product.productType.includes('Ticket')),
      )
      .map((item) => {
        return item.node.lineItems.edges.reduce((acc, lineItem) => {
          if (lineItem.node.variant?.product.productType === 'Ticket') {
            return acc + lineItem.node.quantity
          }
          return acc
        }, 0)
      })
      .reduce((acc, item) => acc + item, 0)

    return { ...res, totalEntries: totalEntries ?? 0, totalTickets: `${totalTickets} Tickets` }
  }
  return null
}

export function useShopifyMultipassForOrders() {
  const angelAccessToken = getStringOrEmptyString(getValidString(getCookie(AUTH_JWT_COOKIE)))
  const shopifyClient = useMemo(() => getShopifyClient(), [])
  const [multipassToken, setMultipassToken] = useState<string | null>(null)
  const [shopifiyOrderData, setShopifiyOrderData] = useState<ShopifyOrderData | null>(null)

  useEffect(() => {
    if (multipassToken == null) {
      getMultipassToken(angelAccessToken)
        .then((multipassToken) => {
          setMultipassToken(multipassToken)
        })
        .catch(() => {
          setMultipassToken(null)
        })
    } else {
      shopifyClient
        .mutate<GetShopifyAccessTokenFromMultipassMutation, GetShopifyAccessTokenFromMultipassMutationVariables>({
          mutation: getShopifyAccessTokenFromMultipass,
          variables: {
            multipassToken: multipassToken ?? '',
          },
        })
        .then((res) => {
          if (res?.data?.customerAccessTokenCreateWithMultipass?.customerAccessToken?.accessToken) {
            const accessToken = res?.data?.customerAccessTokenCreateWithMultipass?.customerAccessToken?.accessToken
            return shopifyClient.query<GetShopifyCustomerAndOrdersQuery, GetShopifyCustomerAndOrdersQueryVariables>({
              query: getShopifyCustomerAndOrders,
              variables: {
                customerAccessToken: accessToken ?? '',
              },
            })
          }
        })
        .then((res) => setShopifiyOrderData(sumEntriesAndTickets(res)))
        .catch(() => {
          setMultipassToken(null)
          setShopifiyOrderData(null)
        })
    }
  }, [angelAccessToken, multipassToken, shopifyClient])
  return shopifiyOrderData
}

const validateCartCheckoutUrl = (cart: Cart) => {
  const checkoutUrl = cart?.checkoutUrl

  if (!checkoutUrl || checkoutUrl.includes('undefined')) {
    throw new Error('Invalid Checkout Url')
  }
}

const updateCartWithCheckoutAlias = (cart: Cart): Cart => {
  const checkoutUrl = cart?.checkoutUrl

  if (cart != null && checkoutUrl && checkoutUrl.includes('shop.angel.com')) {
    return { ...cart, checkoutUrl: checkoutUrl.replace('shop.angel.com', 'checkout.angel.com') }
  }
  return cart
}

export function useShopifyProduct({ productId }: { productId: string }) {
  const shopifyClient = useMemo(() => {
    try {
      return getShopifyClient()
    } catch (error) {
      logger().error('Error fetching PIF product data', error)
      return null
    }
  }, [])

  const { data, loading, error } = useQuery(GetShopifyProduct, {
    variables: {
      id: productId,
    },
    client: shopifyClient || undefined,
    errorPolicy: 'all',
    skip: !shopifyClient,
  })

  return { data, loading, error }
}

export function useShopifyCart() {
  const shopifyClient = useMemo(() => getShopifyClient(), [])

  const [applyDiscountCodeMutation] = useMutation(ApplyDiscountCode, { client: shopifyClient })

  const [createCartMutation] = useMutation<CreateShopifyCartMutation, CreateShopifyCartMutationVariables>(
    CreateShopifyCart,
    { client: shopifyClient },
  )

  const [addLinesMutation] = useMutation<AddLinesShopifyCartMutation, AddLinesShopifyCartMutationVariables>(
    AddLinesShopifyCart,
    { client: shopifyClient },
  )

  const [updateLinesMutation] = useMutation<UpdateLinesShopifyCartMutation, UpdateLinesShopifyCartMutationVariables>(
    UpdateLinesShopifyCart,
    { client: shopifyClient },
  )

  const [removeLinesMutation] = useMutation<RemoveLinesShopifyCartMutation, RemoveLinesShopifyCartMutationVariables>(
    RemoveLinesShopifyCart,
    { client: shopifyClient },
  )

  const addLinesToCart = useCallback(
    async ({ lines, cartId }: { lines: CartLineInput[]; cartId: string }) => {
      try {
        const { data } = await addLinesMutation({ variables: { lines, cartId } })

        const cart = data?.cartLinesAdd?.cart

        if (cart) {
          validateCartCheckoutUrl(cart as Cart)
        }

        return { cart: updateCartWithCheckoutAlias(cart as Cart), errors: data?.cartLinesAdd?.userErrors }
      } catch (error) {
        return handleCartUnknownError(error, 'AddLinesShopifyCart')
      }
    },
    [addLinesMutation],
  )

  const updateLinesInCart = useCallback(
    async ({ lines, cartId }: { lines: CartLineUpdateInput[]; cartId: string }) => {
      try {
        const { data } = await updateLinesMutation({ variables: { lines, cartId } })

        const cart = data?.cartLinesUpdate?.cart

        if (cart) {
          validateCartCheckoutUrl(cart as Cart)
        }

        return { cart: updateCartWithCheckoutAlias(cart as Cart), errors: data?.cartLinesUpdate?.userErrors }
      } catch (error) {
        return handleCartUnknownError(error, 'UpdateLinesShopifyCart')
      }
    },
    [updateLinesMutation],
  )

  const removeLinesFromCart = useCallback(
    async ({ lineIds, cartId }: { lineIds: string[]; cartId: string }) => {
      try {
        const { data } = await removeLinesMutation({ variables: { lineIds, cartId } })

        const cart = data?.cartLinesRemove?.cart

        if (cart) {
          validateCartCheckoutUrl(cart as Cart)
        }

        return { cart: updateCartWithCheckoutAlias(cart as Cart), errors: data?.cartLinesRemove?.userErrors }
      } catch (error) {
        return handleCartUnknownError(error, 'RemoveLinesShopifyCart')
      }
    },
    [removeLinesMutation],
  )

  const createCart = useCallback(
    async ({ lines, attributes }: { lines?: CartLineInput[]; attributes?: AttributeInput[] } = {}) => {
      try {
        const { data } = await createCartMutation({
          variables: { lines: lines as CartLineInput[], attributes: attributes as AttributeInput[] },
        })

        const cart = data?.cartCreate?.cart

        if (cart) {
          validateCartCheckoutUrl(cart as Cart)
        }

        return { cart: updateCartWithCheckoutAlias(cart as Cart), errors: data?.cartCreate?.userErrors }
      } catch (error) {
        return handleCartUnknownError(error, 'CreateShopifyCart')
      }
    },
    [createCartMutation],
  )

  const applyDiscountCode = useCallback(
    async ({ cartId, discountCodes }: { cartId: string; discountCodes: string[] }) => {
      try {
        const { data } = await applyDiscountCodeMutation({
          variables: { cartId, discountCodes },
        })

        const cart = data?.cartDiscountCodesUpdate?.cart

        if (cart) {
          validateCartCheckoutUrl(cart as Cart)
        }

        return { cart: updateCartWithCheckoutAlias(cart as Cart), errors: data?.cartDiscountCodesUpdate?.userErrors }
      } catch (error) {
        return handleCartUnknownError(error, 'ApplyDiscountCode')
      }
    },
    [applyDiscountCodeMutation],
  )

  return { createCart, addLinesToCart, removeLinesFromCart, updateLinesInCart, applyDiscountCode }
}
