import { ApolloError } from '@apollo/client'
import { logger } from '@/utils/logging'

export const handleCartUnknownError = (error: unknown, context: ShopifyErrorContext) => {
  let message = 'An unknown error occurred'

  if (error instanceof ApolloError) {
    if (error.graphQLErrors.length > 0) {
      message = error.graphQLErrors.map((e) => e.message).join(', ')
    } else if (error.networkError) {
      message = `Network error: ${error.networkError.message}`
    } else {
      message = error.message
    }
  } else if (error instanceof Error) {
    message = error.message
  }

  notifyBugsnagShopify('Shopify Cart Unexpected Error', { context, error: { message } })

  return {
    cart: null,
    errors: [{ message, field: null, code: null }],
  }
}

export type ShopifyErrorContext =
  | 'CreateShopifyCart'
  | 'AddLinesShopifyCart'
  | 'RemoveLinesShopifyCart'
  | 'UpdateLinesShopifyCart'
  | 'ApplyDiscountCode'

interface ShopifyBugsnagMetadata {
  context: ShopifyErrorContext
  error?: { message: string }
  [p: string]: { message: string } | string | undefined
}

export const notifyBugsnagShopify = (error: string, metadata: ShopifyBugsnagMetadata) => {
  logger().error(`Critical Shopify Failure`, { metadata, error })
}
