import { useMemo } from 'react'
import { getCookie } from 'cookies-next'
import { SEGMENT_ANONYMOUS_ID_COOKIE } from '@/constants/cookies'
import { useUser } from '@/services/UserService'
import { useSafeAnalytics } from '@/utils/analytics'

interface ExperimentDataOptions {
  funnel?: string
  category?: string
  project_slug?: string
}

const anonId = getCookie(SEGMENT_ANONYMOUS_ID_COOKIE)

export const useShopifyUserAttributes = (options: ExperimentDataOptions = {}) => {
  const { userId } = useUser()
  const { user } = useSafeAnalytics()

  return useMemo(() => {
    const experimentData = {
      id: user()?.anonymousId,
      userId,
      anon_id: anonId ?? user()?.anonymousId,
      timestamp: Date.now(),
      ...options,
    }

    return [
      {
        key: 'gb_data',
        value: JSON.stringify(experimentData),
      },
    ]
  }, [options, user, userId])
}
